import React, { useState } from 'react'

import { useToolbarState, Toolbar, ToolbarItem } from 'reakit/Toolbar'
import styled from 'styled-components'

import { FiSearch } from 'react-icons/fi'

import PurpleButtonStyled from './PurpleButton.styled'

import ClientOrDoctor from './ClientOrDoctor'

import { Transition } from 'react-transition-group'
import animation from './animation.styled'
import { theme } from 'styled-tools'
import { navigate, Link } from 'gatsby'
import ScheduleModal from '../ScheduleModal/scheduleModal'
import sanitizeRegex from '@monorepo-amais/commons/utils/sanitizeRegex'
import MySchedules from '../MySchedules/MySchedules'

const amaisSP =
	process.env.GATSBY_COSMIC_BUCKET === 'amaissp' || process.env.GATSBY_COSMIC_BUCKET === 'labs-amais' ? true : false

const StyledToolbar = styled(Toolbar)`
	box-sizing: border-box;
	-webkit-tap-highlight-color: rgba(0, 0, 0, 0);
	font-family: '-apple-system', 'system-ui, BlinkMacSystemFont', 'Segoe UI', 'Helvetica Neue', Helvetica, Arial,
		sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol';
	display: flex;
	flex-direction: row;
	&& {
		padding: 0 15px;
		background-color: ${theme('colors.white')};
		align-items: center;
		justify-content: space-between;
		margin: 0 auto;
		max-width: ${theme('container')};
		${animation};

		@media (max-width: 1024px) {
			padding: 1px 5px;
		}
	}
`

const LogoWrapper = styled.h1`
	height: 61px;
	margin: 0;
	/* margin-right: 5%; */
	margin-right: 2%;

	padding: 0.5rem 0 0 0;

	.text-logo {
		position: absolute;
		text-indent: -9999px;
	}

	@media (max-width: 1290px) {
		max-width: 20vw;
		margin-right: 1%;
	}

	@media (max-width: 1100px) {
		max-width: 19vw;
		margin-right: 1%;
	}
`

const SearchInput = styled.input`
	&& {
		outline: none;
		//border: none;
		//border-bottom: 1px solid ${theme('colors.grayab')};
		border: 1px solid ${theme('colors.grayab')};
		border-radius: 8px;

		font-family: 'fs_lolaregular' !important;
		font-size: 16px;
		font-weight: 400;
		font-style: normal;
		font-stretch: normal;
		line-height: 1.28;
		letter-spacing: normal;
		text-align: left;
		width: 360px;
		color: #373f41;
		padding: 12px;
		padding-right: 20px;

		::placeholder {
			/* Chrome, Firefox, Opera, Safari 10.1+ */
			color: #373f41;
			opacity: 1; /* Firefox */
		}

		@media (max-width: 1260px) {
			font-size: 16px;
			font-weight: 400;
			font-style: normal;
			font-stretch: normal;
			line-height: 1.28;
			letter-spacing: normal;
			text-align: left;
			width: 330px;
			padding: 12px;
			padding-right: 20px;
		}

		@media (max-width: 1060px) {
			font-size: 16px;
			font-weight: 400;
			font-style: normal;
			font-stretch: normal;
			line-height: 1.28;
			letter-spacing: normal;
			text-align: left;
			width: 290px;
			padding: 12px;
			padding-right: 20px;
		}
	}
`

const SearchButton = styled.button`
	cursor: pointer;
	border: none;
	background: none;
	position: absolute;
	right: 2px;
	top: 51%;
	transform: translateY(-50%);
	outline: none;

	svg {
		color: #009edb !important;
	}
`

const SearchIcon = styled(FiSearch)`
	color: ${theme('colors.grayb8')};
	font-size: 18px;
	transition: 0.2s all ease-in;
`

const CTAButton = styled.h2`
	margin: 0;
	.btn-schedule {
		${PurpleButtonStyled}
		background-color: #009FDE;
		//margin-right: 10px;
		display: flex;
		align-items: center;
		justify-content: center;
		text-decoration: none;
		font-weight: bold;
		height: 48px;
		width: ${amaisSP ? '260px' : '180px'};
		padding: 1rem 2rem;

		&:not([disabled]):hover {
			background-color: #009fde;
			border-color: #009fde;
			opacity: 0.8;
		}

		&:not([disabled]):active {
			background-color: #009fde;
			border-color: #009fde;
		}

		margin-left: 2% !important;

		@media (max-width: 1290px) {
			width: ${amaisSP ? '250px' : '170px'};
			margin-left: 1% !important;
		}

		@media (max-width: 1250px) {
			width: ${amaisSP ? '250px' : '160px'};
			margin-left: 1% !important;
		}

		@media (max-width: 1100px) {
			width: ${amaisSP ? '245px' : '150px'};
			margin-left: 1% !important;
		}

		@media (max-width: 1050px) {
			width: ${amaisSP ? '245px' : '140px'};
			margin-left: 1% !important;
		}
	}
`

const SearchWrapper = styled.form`
	position: relative;
	display: flex;
	justify-content: center;

	.icon {
		color: ${theme('colors.grayb8')};
		align-self: center;
		margin-left: -20px;
		z-index: 99999;
		height: 45px;
		/* position: absolute; */
	}
`

const WhiteBackground = styled.div`
	background-color: ${theme('colors.white')};
	width: 100%;
`

/**
 * Parte "branca" da barra em Desktop.
 * a prop 'show' é do RTG.
 * concentra a prop toolbar do Reakit.
 */

export const selfServiceBrands = ['amaissp', 'amaispr', 'amaispe', 'irn', 'diagnoson', 'labs-amais']

export default function Whitebar({ show, toggleType, type, logo, contatos, contatosAgendar, isClient }) {
	const toolbar = useToolbarState()
	const [inputSearch, setInputSearch] = useState('')
	const [showSchedule, setShowSchedule] = useState(false)

	const handleSearch = e => {
		e.preventDefault()
		if (inputSearch !== '') {
			navigate(`/busca?q=${inputSearch}`)
			setInputSearch('')
		}
	}

	const logoLinkTo = isClient ? '/' : '/medico/'

	return (
		<>
			<ScheduleModal
				showSchedule={showSchedule}
				setShowSchedule={setShowSchedule}
				contatos={contatos}
				contatosAgendar={contatosAgendar}
			></ScheduleModal>
			<Transition in={show} unmountOnExit timeout={50}>
				{state => (
					<WhiteBackground>
						<StyledToolbar {...toolbar} aria-label='Primeira barra de navegação do site' state={state}>
							<ToolbarItem {...toolbar} as={Link} to={logoLinkTo}>
								<LogoWrapper id='logo-wrapper'>
									<span className='text-logo'> a+ Medicina Diagnóstica</span>
									{logo}
								</LogoWrapper>
							</ToolbarItem>
							<ClientOrDoctor toolbar={toolbar} toggleType={toggleType} type={type} isClient={isClient} />
							<SearchWrapper id='form-search-global' onSubmit={handleSearch}>
								<SearchButton type='submit'>
									<SearchIcon />
								</SearchButton>
								<SearchInput
									value={inputSearch}
									onChange={e => setInputSearch(e.target.value?.replace(sanitizeRegex, ''))}
									placeholder='Busque em todo o site'
									id='input-search-global'
								/>
								{/* <FiSearch className='icon' /> */}
							</SearchWrapper>
							{process.env.GATSBY_COSMIC_BUCKET === 'amaissp' ? (
								<CTAButton
									id='button-scheduling-exams'
									onClick={() => window.open('https://agendamento.amaissaude.com.br', '_self')}
								>
									<button className='btn-schedule'>Agendar exames e vacinas</button>
								</CTAButton>
							) : process.env.GATSBY_COSMIC_BUCKET === 'labs-amais' ? (
								<CTAButton
									id='button-scheduling-exams'
									onClick={() => window.open('https://agendamento.labsamais.com.br/', '_self')}
								>
									<button className='btn-schedule'>Agendar exames e vacinas</button>
								</CTAButton>
							) : (
								<CTAButton id='button-scheduling-exams' onClick={() => setShowSchedule(true)}>
									<button className='btn-schedule'>Agendar</button>
								</CTAButton>
							)}
							{selfServiceBrands.includes(process.env.GATSBY_COSMIC_BUCKET) && <MySchedules />}
						</StyledToolbar>
					</WhiteBackground>
				)}
			</Transition>
		</>
	)
}
