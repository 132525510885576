import React from 'react'
import styled, { css } from 'styled-components'
import { theme } from 'styled-tools'

const styleDefault = css`
	color: ${theme('colors.black33')};
	/* text-decoration: underline;
	text-decoration-color: ${props => props.theme.colors.primary};
	text-underline-position: under; */
`

const H2 = styled.h2`
	${styleDefault}
	font-size: 20px;
	margin: 14px 0 17px;
	margin-block-end: 0.3em;
`

const Underline = styled.div`
	${styleDefault}
	height: 5px;
	width: 90px;
	margin-top: 5px;
	border-radius: 3px;
	background: ${theme('colors.primary')};

	@media (max-width: 768px) {
		margin-left: 15px;
	}
`

const H1 = styled.h3`
	${styleDefault}
	font-size: 32px !important;
	margin: 9.3px 0;

	@media (min-width: 768px) {
		font-size: 40px;
		// margin: 30px 15px 40px;
	}
	@media (min-width: 1024px) {
		// margin: 30px 0 0;
	}

	@media (max-width: 768px) {
		padding-left: 15px;
	}
`

const HighLightPhone = styled.a`
	text-decoration: none;
	color: ${props => props.theme.colors.primary};
	font-size: 16px;
	margin: 9.3px 0;
`

const HighLight = styled.strong`
	color: ${props => props.theme.colors.primary};
	font-size: 16px;
	margin: 9.3px 0;
`

const SubTitleCard = styled.h6`
	font-size: 18px;
	font-weight: 500;
	font-style: normal;
	font-stretch: normal;
	line-height: 1.33;
	letter-spacing: normal;
	color: #464646;
	margin: 0;
	padding: 10px 0;

	@media (max-width: 768px) {
		display: none;
	}
`

const MainTitle = ({ children, id }) => (
	<div>
		<H1 id={id}>{children}</H1>
		<Underline />
	</div>
)

const SubTitle = ({ children, id }) => (
	<div>
		<H2 id={id}>{children}</H2>
		<Underline />
	</div>
)

export { MainTitle, SubTitle, HighLight, SubTitleCard, HighLightPhone }
